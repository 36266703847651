<template>
    <div>
        <div class="card-toolbar mb-5">
            <!-- journals-setting.create -->
            <router-link v-if="$can('journal_settings.create')" :to="{name:'journals-setting.create',query:{doc_id: doc_id}}" class="btn btn-primary font-weight-bolder">
                <v-icon>mdi-plus</v-icon> 
                {{ $t('journals_setting.new_journals_setting') }}
            </router-link>
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
        </div>

        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row">
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="document_type">
                                {{$t('journals_setting.document_name')}}
                            </label>
                            <select name="" id="document_name" v-model="filters.document_id" type="text" class="custom-select" v-if="doc_id" disabled>
                                <option value="">{{$t('all')}}</option>
                                <option v-for="(row, index) in documents_list" :value="row.id" :key="index">{{ row.name }}</option>
                            </select>
                            <multiselect v-else v-model="document"
                                :placeholder="$t('document_type_setting.document_name')"
                                label="name"
                                track-by="id"
                                :options="documents_list"
                                :multiple="false"
                                :taggable="true"
                                :show-labels="false"
                                :show-no-options="false"
                                :show-no-results="false">
                            </multiselect>
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label>{{$t('journals_setting.journal_title')}}</label>
                            <input v-model="filters.journal_title" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="document_type">
                                {{$t('journals_setting.journal_type')}}
                            </label>
                            <select name="" id="document_type" v-model="filters.journal_type" type="text" class="custom-select">
                                <option value="">{{$t('all')}}</option>
                                <option v-for="(row, index) in journal_type_list" :value="row.id" :key="index">{{ row.title }}</option>
                            </select>
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="is_active">{{$t('journals_setting.is_active')}}</label>
                            <select name="" id="is_active" v-model="filters.is_active" type="text" class="custom-select">
                                <option value="">{{$t('all')}}</option>
                                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
                            </select>
                        </div>
                        <div class="form-group d-inline-flex col-md-6 mt-10">
                            <b-button class="mt-auto mr-2" variant="primary" @click="doFilter"><i class="fas fa-search"></i> {{$t('search')}}</b-button>
                            <b-button class="mt-auto mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i> {{$t('reset_search')}}</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-body">

                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

                    <template slot="columns_doc" slot-scope="props">
                        <span class="badge badge-light" v-for="(name, index) in props.row.columns_doc" :key="index">{{name}}</span>
                    </template>
                    <template slot="actions" slot-scope="props">
                        <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" v-if="$can('journal_settings.update')" @click="editItem(props.row)">mdi-pencil</v-icon>
                        <v-icon small class="mr-2 text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('journal_settings.delete')" @click="deleteItem(props.row)">mdi-delete</v-icon>
                    </template>
                </v-server-table>
                <!--end: Datatable-->
            </div>
        </div>
        <!--end::User-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "index-journals-setting",
        data() {
            return {
                mainRoute: 'accounting/journal_settings',
                subMainRoute: 'accounting/journal_setting',
                mainRouteDependency: 'base/dependency',

                showAdvancedSearch: false,
                dataList: [],
                filters: {
                    document_id: null,
                    journal_title: null,
                    journal_type: null,
                    is_active: null,
                },
                journal_type_list: [
                    // {id: 1, title: this.$t('journals_setting.simple')},
                    // {id: 2, title: this.$t('journals_setting.complex')},
                ],
                doc_id: this.$route.query.doc_id,
                status_list: [
                    {id: 1, title: this.$t('active')},
                    {id: 0, title: this.$t('inactive')},
                ],
                documents_list: [],
                document : null,

                columns: ['document_name', 'journal_title', 'journal_type_name', 'status_name', 'actions'],
            }
        },
        watch: {
            document: function (val) {
                if(this.doc_id) return;
                if (val) {
                    this.filters.document_id = val.id;
                } else {
                    this.filters.document_id = null;
                }
            },
        },
        computed: {
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        limit: that.$t('records'),
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        document_name: that.$t('journals_setting.document_name'),
                        journal_title: that.$t('journals_setting.journal_title'),
                        journal_type_name: that.$t('journals_setting.journal_type'),
                        status_name: that.$t('journals_setting.status'),
                        actions: that.$t('actions'),

                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'id'},

                    alwaysShowPerPageSelect: true,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: 0,
                            byColumn: data.byColumn,
                            limit: data.limit,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(that.mainRoute, {..._params});

                    },
                    responseAdapter(resp) {
                        that.dataList = resp.data.data.data;
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    },

                }

            },
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.journals_setting")}]);
            if(this.doc_id){
                this.filters.document_id = this.doc_id
                let promise = this.doFilter();
                Promise.all([promise]).then(() => {
                    this.filters.document_id;
                });
            }
            this.getDocumentsList();
            this.getJournalTypeList();
        },
        methods: {
            getFetch() {
                this.$refs.table.refresh();
            },
            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.document_id = null;
                this.filters.journal_title = null;
                this.filters.journal_type = null;
                this.filters.is_active = null;
                this.document = null;

                this.$refs.table.refresh();
            },
            actionDelete(item) {
                ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
            editItem(item) {
                this.$router.push({name: 'journals-setting.edit', params: {id: item.id}});
            },
            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },

            getDocumentsList() {
                ApiService.get(this.mainRouteDependency + "/document_type",{params:{document_id:(this.doc_id? this.doc_id : '')}}).then((response) => {
                    this.documents_list = response.data.data;
                });
            },

            getJournalTypeList() {
                ApiService.get(this.mainRouteDependency + "/journal_type").then((response) => {
                    this.journal_type_list = response.data.data;
                });
            },

        },
    };
</script>
